import axios from "axios";

export function GetDeviceById(deviceId, adminId, locationId) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/narrowcasting/device?id=${deviceId}&admin_id=${adminId}&location_id=${locationId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  });
}