import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    queue: {}
}

export const queueSlice = createSlice({
    name: 'queue',
    initialState,
    reducers: {
        setQueue: (state, action) => {
            state.queue = action.payload
        }
    }
})

export const { setQueue } = queueSlice.actions;

export const getQueue = (state) => state.queue.queue

export default queueSlice.reducer