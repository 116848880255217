import { Box, Container, CssBaseline, ThemeProvider, Typography } from "@mui/material";
import mainTheme from "../css/themes/mainTheme";
import CopyrightText from "../components/copyrightText";
import VersionText from "../components/versionText";

function App() {
  return (
    <>
      <ThemeProvider theme={mainTheme}>
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Container component="main" fixed>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
              <Box sx={{ marginBottom: 10 }}>
                <Typography variant="h3" gutterBottom>
                  ActaBlue Narrowcasting Player
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6">
                  If you're seeing this page, you've probably entered the wrong URL.
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Head over to the portal and check your device info for the correct URL.
                </Typography>
              </Box>
            </Box>
            <Box sx={{ position: 'fixed', right: '0', bottom: '0', padding: 2 }}>
              <VersionText />
            </Box>
            <Box sx={{ position: 'fixed', left: '0', bottom: '0', padding: 2 }}>
              <CopyrightText />
            </Box>
          </Container>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default App;
