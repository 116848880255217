export function splitDeviceChildren(device) {
    let schedules = []
    let playlists = []
    let views = []

    if (device.schedules) {
        schedules = device?.schedules
        for (const schedule of Object.values(schedules)) {
            if (schedule?.playlists) {
                for (const playlist of Object.values(schedule?.playlists)) {
                    playlists.push(playlist)
                }
            }
        }
        for (const playlist of Object.values(playlists)) {
            if (playlist?.views) {
                for (const view of Object.values(playlist?.views)) {
                    views.push(view)
                }
            }
        }
    }

    return [schedules, playlists, views]
}