import { Box, Container, CssBaseline, ThemeProvider, Typography } from "@mui/material";
import mainTheme from "../css/themes/mainTheme";
import CopyrightText from "../components/copyrightText";
import VersionText from "../components/versionText";

function ScheduleEmpty() {
  return (
    <>
      <ThemeProvider theme={mainTheme}>
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%', // Ensure this Box takes up the full height of its parent
          }}
        >
          <Container component="main" fixed>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
              <Box sx={{ marginBottom: 10 }}>
                <Typography variant="h3" gutterBottom>
                  No schedule set for today
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6">
                  If there is meant to be a schedule played today, please check the portal if your device is setup correctly.
                </Typography>
              </Box>
            </Box>
            <Box sx={{ position: 'fixed', right: '0', bottom: '0', padding: 2 }}>
              <VersionText />
            </Box>
            <Box sx={{ position: 'fixed', left: '0', bottom: '0', padding: 2 }}>
              <CopyrightText />
            </Box>
          </Container>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default ScheduleEmpty;
