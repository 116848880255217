import { Typography } from "@mui/material";

const packageJson = require("../../package.json")
const currentVersion = packageJson.version

export default function VersionText(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Version '}
      {currentVersion}
    </Typography>
  );
}