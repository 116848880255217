export function DeepCopy(input) {
    return JSON.parse(JSON.stringify(input));
}

export function getNextIndex(currentIndex, arrayLength) {
    const nextIndex = currentIndex + 1;

    return nextIndex < arrayLength ? nextIndex : 0;
}

export function isSame(obj1, obj2, seen = new Map()) {
    if (obj1 === obj2) return true;

    if (obj1 == null || obj2 == null) return false;

    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return false;

    if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;

    if (seen.get(obj1) === obj2) return true;
    seen.set(obj1, obj2);

    for (let key in obj1) {
        if (!isSame(obj1[key], obj2[key], seen)) return false;
    }

    return true;
}

export function isEmpty(obj) {
    return Object.keys(obj).length === 0
}