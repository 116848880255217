import { createBrowserRouter } from "react-router-dom";
import App from "../main/app";
import Player from "../main/player";

export default function router() {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <App />
        },
        {
            path: "/view/:deviceId?",
            element: <Player />
        }
    ])

    return router
}