import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import { RouterProvider } from 'react-router-dom';
import router from './router/router';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { Provider } from 'react-redux';
import { store } from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <SnackbarProvider
      preventDuplicate={true}
      action={(snackbarId) => (
        <Button onClick={() => closeSnackbar(snackbarId)}>
          <CloseIcon sx={{ color: 'white' }} />
        </Button>
      )}>
      <Provider store={store}>
        <RouterProvider router={router()} />
      </Provider>
    </SnackbarProvider>
  // </React.StrictMode>
);