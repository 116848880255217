import { format, startOfHour } from "date-fns";

export function getCurrentHour() {
    const currentDate = new Date();
    const roundedTime = startOfHour(currentDate);
    return format(roundedTime, 'HH:mm');
}

export function getCurrentTime() {
    const currentDate = new Date();
    return format(currentDate, 'HH:mm');
}