import { Box, CircularProgress, CssBaseline, ThemeProvider, Typography } from "@mui/material";
import mainTheme from "../css/themes/mainTheme";
import useQuery from "../hooks/useQuery";
import App from "./app";
import { useEffect, useState } from "react";
import { GetDeviceById } from "../helpers/dataHandlers/Narrowcasting";
import { closeSnackbar, useSnackbar } from "notistack";
import Fade from "../components/snackbarTransitions/Fade";
import QueueHandler from "../helpers/queueHandler";
import { DeepCopy, getNextIndex, isEmpty, isSame } from "../helpers/objectHelpers";
import ScheduleEmpty from "../components/scheduleEmpty";
import NarrowcastingVideo from "../components/narrowcastingVideo";

function Player() {
  const query = useQuery();
  const device_id = query.get('deviceId');
  const admin_id = query.get('adminId');
  const location_id = query.get('locationId');
  const queueHandler = QueueHandler();
  const { enqueueSnackbar } = useSnackbar();
  const [device, setDevice] = useState({});
  const [currentPlaylist, setCurrentPlaylist] = useState({});
  const [view, setView] = useState({});
  const [nextViewIndex, setNextViewIndex] = useState(-1);
  const [rotation, setRotation] = useState("rotate-0");
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading...");
  const [retry, setRetry] = useState(false);
  const [scheduleEmpty, setScheduleEmpty] = useState(false);
  const playlistQueue = queueHandler.getFullQueue();

  useEffect(() => {
    if (!device || isEmpty(device)) return

    switch (device.screen_rotation) {
      case 'portrait':
        setRotation("rotate-90")
        break;
      case 'landscape-flipped':
        setRotation("rotate-180")
        break;
      case 'portrait-flipped':
        setRotation("rotate-270")
        break;
      case 'landscape':
      default:
        setRotation("rotate-0")
        break;
    }

    setScheduleEmpty(queueHandler.isTodayScheduleEmpty(device))

    if (!scheduleEmpty) {
      queueHandler.createQueue(device)
    }
    // eslint-disable-next-line
  }, [device])

  useEffect(() => {
    // Function to update the time and set the next timeout
    const updateAndScheduleNextPlaylist = () => {
      const now = new Date();

      const newPlaylist = queueHandler.getCurrentPlaylist()
      if (!isSame(newPlaylist, currentPlaylist)) setCurrentPlaylist(newPlaylist)

      // Calculate the time remaining until the next minute change
      const msUntilNextMinute = (60 - now.getSeconds()) * 1000;
      setTimeout(updateAndScheduleNextPlaylist, msUntilNextMinute);
    };

    // Initial call to set the first timeout
    updateAndScheduleNextPlaylist();

    // Cleanup function to clear timeouts when component unmounts
    return () => clearTimeout(updateAndScheduleNextPlaylist);
    // eslint-disable-next-line
  }, [playlistQueue])

  useEffect(() => {
    if (currentPlaylist && currentPlaylist.views) {
      if (currentPlaylist.views.length > 1) {
        const viewInterval = setInterval(() => {
          const sortedViews = DeepCopy(currentPlaylist).views.sort((a, b) => a.position_order - b.position_order); // TODO: Find a way to do this only once when loading in the data, instead of every interval
          const nextIndex = getNextIndex(nextViewIndex, sortedViews.length);
          setNextViewIndex(nextIndex);
          setView(sortedViews[nextIndex]);
        }, view.seconds * 1000);

        return () => clearInterval(viewInterval);
      } else {
        setView(currentPlaylist.views[0])
      }
    }
    // eslint-disable-next-line
  }, [currentPlaylist, view, nextViewIndex])

  useEffect(() => {
    if (device_id && admin_id && location_id) {
      setLoading(true)
      GetDeviceById(device_id, admin_id, location_id)
        .then(response => {
          setLoading(false)
          try {
            setDevice(response?.data)
          } catch (error) {
            enqueueSnackbar(
              <Box sx={{ textAlign: 'center', padding: 1 }}>
                <Typography variant="h6">
                  <b>(Internal) {error?.message}</b>
                  {error?.response?.data?.detail?.message && (
                    <>
                      <br />
                      {error?.response?.data?.detail?.message}
                    </>
                  )}
                </Typography>
              </Box>,
              {
                variant: 'error',
                persist: true,
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
                TransitionComponent: Fade
              }
            );
          }
        })
        .catch(error => {
          setLoading(false)
          const errorSnackbar = enqueueSnackbar(
            <Box sx={{ textAlign: 'center', padding: 1 }}>
              <Typography variant="h6">
                <b>(API) {error?.message}</b>
                {error?.response?.data?.detail?.message && (
                  <>
                    <br />
                    {error?.response?.data?.detail?.message}
                  </>
                )}
                <br />
                Retrying in 5 seconds...
              </Typography>
            </Box>,
            {
              variant: 'error',
              persist: true,
              anchorOrigin: { vertical: 'top', horizontal: 'center' },
              TransitionComponent: Fade
            }
          );
          const intervalId = setInterval(() => {  // Assign interval to a variable to clear it.
            closeSnackbar(errorSnackbar)
            setLoadingText("Retrying...")
            setTimeout(() => {
              setRetry(!retry)
              clearInterval(intervalId)
            }, 200)
          }, 4800) // Actually interval for 4800ms so the snackbar closes in time
        })
    }
    // eslint-disable-next-line
  }, [retry])

  useEffect(() => {
    if (device) {
      const dataInterval = setInterval(() => {
        GetDeviceById(device_id, admin_id, location_id)
          .then(response => {
            setLoading(false)
            try {
              if (!isSame(response.data, device)) {
                window.location.reload(true)
              }
            } catch (error) {
              enqueueSnackbar(
                <Box sx={{ textAlign: 'center', padding: 1 }}>
                  <Typography variant="h6">
                    <b>(Internal) {error?.message}</b>
                    {error?.response?.data?.detail?.message && (
                      <>
                        <br />
                        {error?.response?.data?.detail?.message}
                      </>
                    )}
                  </Typography>
                </Box>,
                {
                  variant: 'error',
                  persist: true,
                  anchorOrigin: { vertical: 'top', horizontal: 'center' },
                  TransitionComponent: Fade
                }
              );
            }
          })
          .catch(error => {
            setLoading(false)
            const errorSnackbar = enqueueSnackbar(
              <Box sx={{ textAlign: 'center', padding: 1 }}>
                <Typography variant="h6">
                  <b>(API) {error?.message}</b>
                  {error?.response?.data?.detail?.message && (
                    <>
                      <br />
                      {error?.response?.data?.detail?.message}
                    </>
                  )}
                  <br />
                  Retrying in 5 seconds...
                </Typography>
              </Box>,
              {
                variant: 'error',
                persist: true,
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
                TransitionComponent: Fade
              }
            );
            const intervalId = setInterval(() => {  // Assign interval to a variable to clear it.
              closeSnackbar(errorSnackbar)
              setLoadingText("Retrying...")
              setTimeout(() => {
                setRetry(!retry)
                clearInterval(intervalId)
              }, 200)
            }, 4800) // Actually interval for 4800ms so the snackbar closes in time
          })
      }, 10000)

      return () => clearInterval(dataInterval);
    }
    // eslint-disable-next-line
  }, [device])

  return (
    (query.size !== 0 && device_id && admin_id && location_id && device) ?
      <>
        <ThemeProvider theme={mainTheme}>
          <CssBaseline />
          <Box className={rotation}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%'
              }}
            >
              {loading ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <CircularProgress color='inherit' />
                  <Typography variant="body1" sx={{ marginTop: 2 }}>
                    {loadingText ?? "Loading..."}
                  </Typography>
                </Box>
              ) : (
                scheduleEmpty ?
                  <ScheduleEmpty />
                  :
                  <>
                    {view?.content_type === "Video" && (
                      <NarrowcastingVideo {...view} />
                    )}
                    {view?.content_type === "Image" && (
                      <Box component="img" sx={{ position: 'absolute', height: "100%", width: "100%" }} alt="image" src={view?.image} />
                    )}
                  </>
              )}
            </Box>
          </Box>
        </ThemeProvider>
      </>
      :
      <>
        <App />
      </>
  );
}

export default Player;
